<template>
	<div class="form-data" style="padding-right: 24px;">
		<Form ref="formValidate" :model="formData" :rules="ruleValidate" :label-width="100">
			<FormItem label="版本名称" prop="title">
				<Input v-model="formData.title" maxlength="20" placeholder="请输入版本名称"></Input>
			</FormItem>
			<FormItem label="系统类型">
				<RadioGroup v-model="formData.type">
					<Radio :label="0">Sass</Radio>
					<Radio :label="1">私有化部署</Radio>
				</RadioGroup>
			</FormItem>
			<FormItem label="是否免费">
				<i-switch v-model="formData.is_free" :true-value="1" :false-value="0" size="large">
					<span slot="open">免费</span>
					<span slot="close">收费</span>
				</i-switch>
			</FormItem>
			<FormItem label="成本价" v-if="!formData.is_free">
				<InputNumber v-model="formData.cost_price" style="width: 160px;" :max="99999999" :min="0" :precision="2"
					:formatter="value => `${value} 元`" :parser="value => value.replace('元', '')" :active-change="false">
				</InputNumber>
			</FormItem>
			<FormItem label="现价" v-if="!formData.is_free">
				<InputNumber v-model="formData.current_price" style="width: 160px;" :max="99999999" :min="0"
					:precision="2" :formatter="value => `${value} 元`" :parser="value => value.replace('元', '')"
					:active-change="false"></InputNumber>
			</FormItem>
			<FormItem label="续费价格" v-if="!formData.is_free">
				<InputNumber v-model="formData.renewal_price" style="width: 160px;" :max="99999999" :min="0"
					:precision="2" :formatter="value => `${value} 元`" :parser="value => value.replace('元', '')"
					:active-change="false"></InputNumber>
			</FormItem>
			<FormItem label="版本描述" prop="describe">
				<Input v-model="formData.describe" type="textarea" maxlength="50" show-word-limit
					:autosize="{minRows: 3,maxRows: 5}" placeholder="请输入版本描述..."></Input>
			</FormItem>
			<FormItem>
				<Button type="primary" @click="saveData">保存</Button>
				<Button style="margin-left: 10px;" @click="close">取消</Button>
			</FormItem>
		</Form>
	</div>
</template>

<script>
	export default {
		name: "VersionFromData",
		data() {
			return {
				formData: {
					id: null,
					type: 0,
					is_free: 0,
					title: '',
					describe: '',
					cost_price: 0,
					current_price: 0,
					renewal_price: 0,
					days: 365,
				},
				ruleValidate: {
					title: [{
							required: true,
							message: '请输入版本名称',
							trigger: 'blur'
						},
						{
							type: 'string',
							max: 20,
							message: '版本名称最多20个字符',
							trigger: 'blur'
						}
					],
					describe: [{
							required: true,
							message: '请输入版本描述',
							trigger: 'blur'
						},
						{
							type: 'string',
							max: 50,
							message: '版本描述最多20个字符',
							trigger: 'blur'
						}
					],
				}
			}
		},
		created() {
			this.formData.id = this.$route.query.id
			if (this.formData.id) this.init()
		},
		methods: {
			init() {
				var that = this
				this.requestApi('/adm/get_system_version_data', {
					id: this.formData.id
				}).then((res) => {
					if (res.status) {
						that.formData = res.data
					}
				})
			},
			saveData() {
				var that = this
				this.$refs.formValidate.validate((valid) => {
					if (valid) {
						that.requestApi('/adm/save_system_version', {
							data: that.formData
						}).then((res) => {
							if (res.status) {
								that.alertSucc(res.msg)
								setTimeout(function() {
									that.toUrl('/version/list')
								}, 300)
							} else {
								that.alertErr(res.msg)
							}
						})
					}
				})
			},
			close() {
				this.toUrl('/version/list')
			}
		}
	}
</script>

<style scoped>

</style>